import React from "react";

export const SwipperIcon = ({
  width = "35",
  height = "32",
  fill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1089 6.7041C22.3798 5.37077 20.1923 4.704 18.7339 4.704M10.7655 19.2987V13.3387M10.7655 13.3387V5.976C10.7655 4.88267 11.7645 4 12.9617 4C14.1605 4 15.1055 4.88267 15.1055 5.976V11.3107M10.7655 13.3387C8.84487 14.9293 6.7755 16.9387 6.49695 17.452C5.20195 19.252 5.33758 20.124 6.78133 22.328C7.70521 23.717 8.6964 25.0677 9.75195 26.376C10.732 27.3893 10.538 27.3893 11.957 28.3427C13.2257 29.1453 15.8565 29.7053 22.0224 29.1453C27.0463 28.4373 28.2495 24.4333 28.2232 22.5187V17.7933C28.5367 13.8653 26.7386 13.688 23.4734 13.3013M15.1026 14.68V11.3107C15.9192 10.0973 18.8767 10.588 19.4528 12.8787M19.5053 14.6747V13.3387C19.5053 13.2338 19.498 13.1289 19.4834 13.024M19.4542 12.8787L19.4834 13.0253C19.4688 12.9747 19.4591 12.9258 19.4542 12.8787ZM19.4542 12.8787C19.2486 11.2827 23.679 11.672 23.8584 14.4787V16.0053M7.49988 4.704C6.04154 4.7041 3.42143 5.37077 2.69226 6.7041"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
