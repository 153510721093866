import React, { FC } from "react";

import styles from "./Status.module.scss";
import { UnactiveDot } from "icons/UnactiveDot";
import { LocationIcon } from "icons/locationIcon";

type StatusProps = {
  status: string;
  place: string;
};

const Status: FC<StatusProps> = ({ status, place }) => {
  return (
    <div className={styles.statusContainer}>
      <p className={styles.status}>
        <span>
          <UnactiveDot />
        </span>
        {status}
      </p>
      <p className={styles.place}>
        <span>
          <LocationIcon className={styles.blackLocation} />
        </span>
        {place}
      </p>
    </div>
  );
};

export default Status;
