// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupButtonLocal_mainContainer__n-82z {
  width: 358px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 16px;
}

.GroupButtonLocal_filterIcon__GS\\+eg {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.GroupButtonLocal_container__b36rO {
  width: 275px;
  border-radius: 10px;
  padding: 4px 3px 2px 3px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10;
  position: relative;
}
.GroupButtonLocal_container__b36rO .GroupButtonLocal_button__xvaSr {
  width: 50%;
  height: 36px;
  border-radius: 7px;
  padding: 5px 47px 5px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
}
.GroupButtonLocal_container__b36rO .GroupButtonLocal_button__xvaSr p {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: black;
}
.GroupButtonLocal_container__b36rO .GroupButtonLocal_button__xvaSr .GroupButtonLocal_activeTextCommon__8bp-O {
  color: white;
}
.GroupButtonLocal_container__b36rO .GroupButtonLocal_button__xvaSr .GroupButtonLocal_activeTextLocal__tmXK1 {
  color: white;
}

.GroupButtonLocal_active__a5DyO {
  background-color: #32ade6;
}
.GroupButtonLocal_active__a5DyO p {
  color: white;
}

.GroupButtonLocal_activeTextCommon__8bp-O {
  color: white;
}

.GroupButtonLocal_activeTextLocal__tmXK1 {
  color: white;
}

.GroupButtonLocal_filterMenu__PDwzZ {
  position: absolute;
  top: 45px;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/GroupButtonLocal/GroupButtonLocal.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAEF;;AAAA;EACE,YAAA;EACA,mBAAA;EACA,wBAAA;EACA,uBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,OAAA;EACA,kBAAA;AAGF;AAFE;EACE,UAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAIJ;AAHI;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AAKN;AAHI;EACE,YAAA;AAKN;AAFI;EACE,YAAA;AAIN;;AACA;EACE,yBAAA;AAEF;AADE;EACE,YAAA;AAGJ;;AACA;EACE,YAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;AAEF","sourcesContent":[".mainContainer {\n  width: 358px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 12px;\n  margin-left: 16px;\n}\n.filterIcon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.container {\n  width: 275px;\n  border-radius: 10px;\n  padding: 4px 3px 2px 3px;\n  background-color: white;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 10;\n  position: relative;\n  .button {\n    width: 50%;\n    height: 36px;\n    border-radius: 7px;\n    padding: 5px 47px 5px 48px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    transition: 0.4s;\n    p {\n      font-size: 15px;\n      line-height: 18px;\n      font-weight: 400;\n      color: black;\n    }\n    .activeTextCommon {\n      color: white;\n    }\n\n    .activeTextLocal {\n      color: white;\n    }\n  }\n}\n\n.active {\n  background-color: #32ade6;\n  p {\n    color: white;\n  }\n}\n\n.activeTextCommon {\n  color: white;\n}\n\n.activeTextLocal {\n  color: white;\n}\n\n.filterMenu {\n  position: absolute;\n  top: 45px;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `GroupButtonLocal_mainContainer__n-82z`,
	"filterIcon": `GroupButtonLocal_filterIcon__GS+eg`,
	"container": `GroupButtonLocal_container__b36rO`,
	"button": `GroupButtonLocal_button__xvaSr`,
	"activeTextCommon": `GroupButtonLocal_activeTextCommon__8bp-O`,
	"activeTextLocal": `GroupButtonLocal_activeTextLocal__tmXK1`,
	"active": `GroupButtonLocal_active__a5DyO`,
	"filterMenu": `GroupButtonLocal_filterMenu__PDwzZ`
};
export default ___CSS_LOADER_EXPORT___;
