import axios from "axios";

export const fetchNextForm = async () => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const XCommunityId = "0e46a5d2-91d5-4669-988c-d7148c350c07";

    if (!accessToken) {
      throw new Error("Access token отсутствует");
    }

    console.log(
      "Токен и X-Community-Id:",
      accessToken,
      "X-Community-Id:",
      XCommunityId
    );

    const response = await axios.get("https://swiperapp.ru:3000/form/next", {
      headers: {
        "X-Auth-Token": accessToken,
        "X-Community-Id": XCommunityId,
      },
    });
    console.log("Данные профиля получены:", response.data);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении данных профиля:", error);
    throw error;
  }
};
