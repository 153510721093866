import React, { useState } from "react";
import style from "./GroupButtonLocal.module.scss";
import classNames from "classnames";

export const GroupButtonLocal = () => {
  const [activeButtonLocal, setActiveButtonLocal] = useState(false);
  const [activeButtonCommon, setActiveButtonCommon] = useState(true);

  const onCommonClick = () => {
    setActiveButtonCommon(false);
    setActiveButtonLocal(true);
  };

  const onLocalClick = () => {
    setActiveButtonCommon(true);
    setActiveButtonLocal(false);
  };

  return (
    <div className={classNames(style.mainContainer)}>
      <div className={style.container}>
        <div
          className={classNames(
            style.button,
            activeButtonLocal && style.active
          )}
          onClick={onCommonClick}
        >
          <p className={classNames(activeButtonLocal && style.activeTextLocal)}>
            Общая
          </p>
        </div>
        <div
          className={classNames(
            style.button,
            activeButtonCommon && style.active
          )}
          onClick={onLocalClick}
        >
          <p
            className={classNames(activeButtonCommon && style.activeTextCommon)}
          >
            Локальная
          </p>
        </div>
      </div>
    </div>
  );
};
