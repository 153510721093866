// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPhoto_title__nzY3d {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.AddPhoto_addPhotoContainer__7UbSw {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  margin: auto;
}

.AddPhoto_addPhotoItem__tcYD\\+ {
  position: relative;
  width: 175px;
  height: 220px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  margin: auto;
}

.AddPhoto_iconLabel__zIAyu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AddPhoto_hiddenInputBtn__aAOvm {
  display: none;
}

.AddPhoto_previewImage__JSc-g {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/AddPhoto/AddPhoto.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".title {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 32px;\n  text-align: left;\n}\n\n.addPhotoContainer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 8px;\n  row-gap: 8px;\n  margin: auto;\n}\n\n.addPhotoItem {\n  position: relative;\n  width: 175px;\n  height: 220px;\n  background-color: rgba(255, 255, 255, 1);\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  cursor: pointer;\n  overflow: hidden;\n  margin: auto;\n}\n\n.iconLabel {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.hiddenInputBtn {\n  display: none;\n}\n\n.previewImage {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `AddPhoto_title__nzY3d`,
	"addPhotoContainer": `AddPhoto_addPhotoContainer__7UbSw`,
	"addPhotoItem": `AddPhoto_addPhotoItem__tcYD+`,
	"iconLabel": `AddPhoto_iconLabel__zIAyu`,
	"hiddenInputBtn": `AddPhoto_hiddenInputBtn__aAOvm`,
	"previewImage": `AddPhoto_previewImage__JSc-g`
};
export default ___CSS_LOADER_EXPORT___;
