import React from "react";

export const VerifiedIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.015 3.11599C12.465 2.91229 13.037 3.14772 13.2122 3.59047C13.4358 4.15541 13.8021 4.86779 14.3608 5.39769C14.9196 5.92759 15.6707 6.275 16.2663 6.48706C16.7332 6.65326 16.9814 7.19574 16.7666 7.62253C16.4771 8.19779 16.1635 8.9485 16.1635 9.5009C16.1635 10.0799 16.508 10.8767 16.8082 11.461C17.0141 11.862 16.8099 12.3693 16.3791 12.5483C15.768 12.8022 14.9554 13.2111 14.3608 13.7751C13.8206 14.2874 13.4603 14.9278 13.2349 15.4404C13.0455 15.8712 12.4794 16.0971 12.0357 15.8952C11.4266 15.6181 10.6236 15.3138 10.0345 15.3138C9.42586 15.3138 8.58911 15.6385 7.9736 15.9225C7.54896 16.1185 6.99429 15.9271 6.78761 15.5244C6.51801 14.9992 6.09812 14.3158 5.52794 13.7751C4.94694 13.224 4.20988 12.821 3.65208 12.566C3.23656 12.376 3.04523 11.8784 3.24825 11.4855C3.55129 10.8991 3.90557 10.088 3.90557 9.5009C3.90557 8.94221 3.58474 8.18066 3.29255 7.60294C3.0797 7.18208 3.31779 6.64522 3.77205 6.46557C4.31256 6.25182 4.98771 5.91007 5.52794 5.39769C6.12255 4.83375 6.55371 4.06313 6.82143 3.48351C7.01013 3.07496 7.54508 2.88123 7.96786 3.07658C8.58395 3.36126 9.42408 3.68802 10.0345 3.68802C10.617 3.68802 11.4085 3.39055 12.015 3.11599ZM13.1131 7.24968C12.9153 7.05438 12.5947 7.05438 12.3969 7.24968L8.95319 10.6575L7.63648 9.35723C7.4387 9.16193 7.11805 9.16193 6.92028 9.35723C6.72251 9.55253 6.72251 9.86918 6.92028 10.0645L8.63494 11.7577C8.74102 11.8578 9.01396 11.9978 9.25705 11.7577L13.1131 7.94983C13.3109 7.75453 13.3109 7.44498 13.1131 7.24968Z"
      fill="#007AFF"
    />
  </svg>
);
