import React from "react";
export const MainPageIcon = ({
  width = "40",
  height = "32",
  fill = "#98989E",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1148 11.4754C13.1148 10.1717 14.1717 9.11475 15.4754 9.11475H16.2623C17.566 9.11475 18.623 10.1717 18.623 11.4754V12.2623C18.623 13.566 17.566 14.623 16.2623 14.623H15.4754C14.1717 14.623 13.1148 13.566 13.1148 12.2623V11.4754Z"
        fill={fill}
      />
      <path
        d="M13.1148 19.7377C13.1148 18.434 14.1717 17.377 15.4754 17.377H16.2623C17.566 17.377 18.623 18.434 18.623 19.7377V20.5246C18.623 21.8283 17.566 22.8852 16.2623 22.8852H15.4754C14.1717 22.8852 13.1148 21.8283 13.1148 20.5246V19.7377Z"
        fill={fill}
      />
      <path
        d="M21.377 11.4754C21.377 10.1717 22.434 9.11475 23.7377 9.11475H24.5246C25.8283 9.11475 26.8852 10.1717 26.8852 11.4754V12.2623C26.8852 13.566 25.8283 14.623 24.5246 14.623H23.7377C22.434 14.623 21.377 13.566 21.377 12.2623V11.4754Z"
        fill={fill}
      />
      <path
        d="M21.377 19.7377C21.377 18.434 22.434 17.377 23.7377 17.377H24.5246C25.8283 17.377 26.8852 18.434 26.8852 19.7377V20.5246C26.8852 21.8283 25.8283 22.8852 24.5246 22.8852H23.7377C22.434 22.8852 21.377 21.8283 21.377 20.5246V19.7377Z"
        fill={fill}
      />
      <path
        d="M15.4754 14.623H16.2623M15.4754 14.623C14.1717 14.623 13.1148 13.566 13.1148 12.2623V11.4754M15.4754 14.623L13.1148 11.4754M16.2623 14.623C17.566 14.623 18.623 13.566 18.623 12.2623V11.4754C18.623 10.1717 17.566 9.11475 16.2623 9.11475M16.2623 14.623L18.2857 11.4286L16.2623 9.11475M16.2623 9.11475H15.4754M15.4754 9.11475C14.1717 9.11475 13.1148 10.1717 13.1148 11.4754M15.4754 9.11475L13.1148 11.4754M23.7377 14.623H24.5246M23.7377 14.623C22.434 14.623 21.377 13.566 21.377 12.2623M23.7377 14.623L21.377 12.2623M21.377 12.2623V11.4754M26.8852 12.2623V11.4754M26.8852 12.2623C26.8852 13.566 25.8283 14.623 24.5246 14.623M26.8852 12.2623L24.5246 14.623M24.5246 9.11475H23.7377M24.5246 9.11475C25.8283 9.11475 26.8852 10.1717 26.8852 11.4754M24.5246 9.11475L26.8852 11.4754M23.7377 9.11475C22.434 9.11475 21.377 10.1717 21.377 11.4754M23.7377 9.11475L21.377 11.4754M15.4754 28H24.5246C28.6531 28 32 24.6531 32 20.5246V11.4754C32 7.34686 28.6531 4 24.5246 4H15.4754C11.3469 4 8 7.34686 8 11.4754V20.5246C8 24.6531 11.3469 28 15.4754 28ZM15.4754 22.8852H16.2623C17.566 22.8852 18.623 21.8283 18.623 20.5246V19.7377C18.623 18.434 17.566 17.377 16.2623 17.377H15.4754C14.1717 17.377 13.1148 18.434 13.1148 19.7377V20.5246C13.1148 21.8283 14.1717 22.8852 15.4754 22.8852ZM23.7377 22.8852H24.5246C25.8283 22.8852 26.8852 21.8283 26.8852 20.5246V19.7377C26.8852 18.434 25.8283 17.377 24.5246 17.377H23.7377C22.434 17.377 21.377 18.434 21.377 19.7377V20.5246C21.377 21.8283 22.434 22.8852 23.7377 22.8852Z"
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  );
};
