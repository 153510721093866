// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMenu_container__LTz8T {
  display: flex;
  justify-content: space-between;
}

.MainMenu_tab__Tgcni {
  padding: 8px 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: #98989e;
  width: 100%;
  transition: 0.2s;
  cursor: pointer;
}
.MainMenu_tab__Tgcni svg {
  margin-bottom: -3px;
}
.MainMenu_tab_active__etsHf {
  color: #32ade6;
}`, "",{"version":3,"sources":["webpack://./src/components/MainMenu/MainMenu.module.scss","webpack://./src/scss/colors.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,8BAAA;AAAF;;AAGA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,cCrBG;ADoBP","sourcesContent":["@import \"scss/colors\";\n.container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.tab {\n  padding: 8px 10px;\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 12px;\n  color: #98989e;\n  width: 100%;\n  transition: 0.2s;\n  cursor: pointer;\n\n  svg {\n    margin-bottom: -3px;\n  }\n\n  &_active {\n    color: $blue;\n  }\n}\n","$blue: #32ade6;\n$disabled: #e8e8e9;\n$textDisabled: #b9b9ba;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainMenu_container__LTz8T`,
	"tab": `MainMenu_tab__Tgcni`,
	"tab_active": `MainMenu_tab_active__etsHf`
};
export default ___CSS_LOADER_EXPORT___;
