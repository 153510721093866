import React from "react";

export const ReturnIcon = ({ width = "32", height = "32", fill = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.92 11.3335L17.1614 14.5735L15.748 15.9881L11.5054 11.7468L10.5627 10.8041C10.4377 10.6791 10.3675 10.5096 10.3675 10.3328C10.3675 10.156 10.4377 9.98649 10.5627 9.86147L15.748 4.6748L17.1614 6.08947L13.9174 9.33347H20C22.1218 9.33347 24.1566 10.1763 25.6569 11.6766C27.1572 13.1769 28 15.2117 28 17.3335C28 19.4552 27.1572 21.49 25.6569 22.9903C24.1566 24.4906 22.1218 25.3335 20 25.3335H5.33337V23.3335H20C21.5913 23.3335 23.1175 22.7013 24.2427 21.5761C25.3679 20.4509 26 18.9248 26 17.3335C26 15.7422 25.3679 14.216 24.2427 13.0908C23.1175 11.9656 21.5913 11.3335 20 11.3335H13.92Z"
        fill={fill}
      />
    </svg>
  );
};
