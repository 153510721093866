// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HintOnCommonFunctions_wrapper__PJjKD {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.71);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 1;
  visibility: visible;
}

.HintOnCommonFunctions_iconsGroup__JMG\\+S {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.HintOnCommonFunctions_container__22KSD {
  width: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HintOnCommonFunctions_iconGroup__kAYBb {
  display: flex;
  gap: 8px;
}

.HintOnCommonFunctions_iconGroupText__c8JX8 {
  font-size: 12px;
  color: #fff;
  text-align: left;
}
.HintOnCommonFunctions_iconGroupText__c8JX8 span {
  display: block;
  color: #98989e;
  font-size: 10px;
  margin-top: 4px;
}

.HintOnCommonFunctions_title__ZyrNj {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 32px;
  color: #fff;
}

.HintOnCommonFunctions_isClosed__v9rpR {
  opacity: 0;
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/HintOnCommonFunctions/HintOnCommonFunctions.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,+BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;UAAA,0BAAA;EACA,4BAAA;EACA,6BAAA;EACA,yCACE;EAEF,UAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,QAAA;AADF;;AAIA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AADF;AAGE;EACE,cAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;AADJ;;AAKA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AAFF;;AAKA;EACE,UAAA;EACA,kBAAA;AAFF","sourcesContent":[".wrapper {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n  background: rgba(0, 0, 0, 0.71);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  backdrop-filter: blur(5px);\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  transition:\n    opacity 0.5s,\n    visibility 0.5s;\n  opacity: 1;\n  visibility: visible;\n}\n\n.iconsGroup {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.container {\n  width: 242px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.iconGroup {\n  display: flex;\n  gap: 8px;\n}\n\n.iconGroupText {\n  font-size: 12px;\n  color: #fff;\n  text-align: left;\n\n  span {\n    display: block;\n    color: #98989e;\n    font-size: 10px;\n    margin-top: 4px;\n  }\n}\n\n.title {\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 25px;\n  margin-bottom: 32px;\n  color: #fff;\n}\n\n.isClosed {\n  opacity: 0;\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HintOnCommonFunctions_wrapper__PJjKD`,
	"iconsGroup": `HintOnCommonFunctions_iconsGroup__JMG+S`,
	"container": `HintOnCommonFunctions_container__22KSD`,
	"iconGroup": `HintOnCommonFunctions_iconGroup__kAYBb`,
	"iconGroupText": `HintOnCommonFunctions_iconGroupText__c8JX8`,
	"title": `HintOnCommonFunctions_title__ZyrNj`,
	"isClosed": `HintOnCommonFunctions_isClosed__v9rpR`
};
export default ___CSS_LOADER_EXPORT___;
