import { useEffect, useState } from "react";
import { fetchNextForm } from "../services/formService";

interface UserProfile {
  id: string;
  name: string;
  photo_links: string[];
  description: string;
  age: number;
}

const useFetchProfiles = () => {
  const [profiles, setProfiles] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        setLoading(true);
        const profileData = await fetchNextForm();
        setProfiles((prevProfiles) => [...prevProfiles, profileData]);
        setLoading(false);
      } catch (err) {
        console.error("Ошибка при загрузке профилей:", err);
        setError("Не удалось загрузить профили");
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  return { profiles, loading, error };
};

export default useFetchProfiles;
