import React, { ChangeEvent, useState } from "react";
import styles from "./InputAbout.module.scss";
import classNames from "classnames";
import Button from "components/Button/Button";
import { ReloadIcon } from "icons/ReloadIcon";

interface InputAboutProps {
  text: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const InputAbout: React.FC<InputAboutProps> = ({ text, onChange }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const changeQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
  };

  const questions = [
    "У вас есть домашние питомцы?",
    "Какой у вас любимый фильм?",
    "Куда вы любите путешествовать?",
    "Какой ваш любимый вид спорта?",
    "Какое ваше любимое блюдо?",
    "Есть ли у вас хобби?",
    "Какое ваше любимое время года?",
  ];

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.trim() !== "" || value === "") {
      onChange(e);
    }
  };

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.inputAboutContainer)}>
        <textarea
          placeholder="Расскажите о себе"
          className={classNames(styles.inputAbout)}
          value={text}
          onChange={handleTextChange} 
        />
      </div>

      <div className={classNames(styles.inputWithIcon)}>
        <input
          type="text"
          placeholder={questions[currentQuestionIndex]}
          className={classNames(styles.inputQuestion)}
          readOnly
        />
        <Button
          title={<ReloadIcon />}
          onClick={changeQuestion}
          type="reload"
        />
      </div>
    </div>
  );
};

export default InputAbout;
