// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterStepTwo_registerStepTwo__nH8sI {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f7;
  padding: 0 15px;
}

.RegisterStepTwo_title__MJVqz {
  left: 16px;
  margin-top: 28px;
  margin-bottom: 8px;
}

.RegisterStepTwo_inputAbout__VbLmg {
  margin-top: 16px;
}

.RegisterStepTwo_button__0pVlZ {
  position: absolute;
  bottom: 24px;
  left: 16px;
  right: 16px;
  margin-top: 139px;
}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterStepTwo/RegisterStepTwo.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;AACF","sourcesContent":[".registerStepTwo {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: #f2f2f7;\n  padding: 0 15px;\n}\n\n.title {\n  left: 16px;\n  margin-top: 28px;\n  margin-bottom: 8px;\n}\n\n.inputAbout {\n  margin-top: 16px;\n}\n\n.button {\n  position: absolute;\n  bottom: 24px;\n  left: 16px;\n  right: 16px;\n  margin-top: 139px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerStepTwo": `RegisterStepTwo_registerStepTwo__nH8sI`,
	"title": `RegisterStepTwo_title__MJVqz`,
	"inputAbout": `RegisterStepTwo_inputAbout__VbLmg`,
	"button": `RegisterStepTwo_button__0pVlZ`
};
export default ___CSS_LOADER_EXPORT___;
