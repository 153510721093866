import React from "react";

export const PreviousIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.44 8.49986L12.871 10.9299L11.811 11.9909L8.629 8.80986L7.922 8.10286C7.82826 8.0091 7.77561 7.88194 7.77561 7.74936C7.77561 7.61678 7.82826 7.48962 7.922 7.39586L11.811 3.50586L12.871 4.56686L10.438 6.99986H15C16.5913 6.99986 18.1174 7.632 19.2426 8.75722C20.3679 9.88244 21 11.4086 21 12.9999C21 14.5912 20.3679 16.1173 19.2426 17.2425C18.1174 18.3677 16.5913 18.9999 15 18.9999H4V17.4999H15C16.1935 17.4999 17.3381 17.0258 18.182 16.1818C19.0259 15.3379 19.5 14.1933 19.5 12.9999C19.5 11.8064 19.0259 10.6618 18.182 9.81788C17.3381 8.97397 16.1935 8.49986 15 8.49986H10.44Z"
      fill="#98989E"
    />
  </svg>
);
