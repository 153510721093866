// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfiles_container__-dF4l {
  position: relative;
  overflow: hidden;
}
.UserProfiles_container__-dF4l .UserProfiles_image__3SeGE {
  width: 390px;
  height: 592px;
  border-radius: 15px 0px 0px 0px;
}
.UserProfiles_container__-dF4l .UserProfiles_image__3SeGE img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(85%);
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o {
  position: absolute;
  top: 386px;
  padding: 0 16px;
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o .UserProfiles_name__ys\\+g2 {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 16px;
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o .UserProfiles_name__ys\\+g2 p {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-right: 8px;
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o .UserProfiles_description__FPvl7 {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o .UserProfiles_buttons__RMeAh {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.UserProfiles_container__-dF4l .UserProfiles_info__4Q57o .UserProfiles_buttons__RMeAh .UserProfiles_reaction__Lj6dq {
  display: flex;
  justify-content: space-between;
  margin-left: 64px;
  width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/components/UserProfiles/UserProfiles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,YAAA;EACA,aAAA;EACA,+BAAA;AAEJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;AAEN;AACE;EACE,kBAAA;EACA,UAAA;EACA,eAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;AAEN;AADM;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAGR;AAAI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;AAEN;AACI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACN;AACM;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,YAAA;AACR","sourcesContent":[".container {\n  position: relative;\n  overflow: hidden;\n  .image {\n    width: 390px;\n    height: 592px;\n    border-radius: 15px 0px 0px 0px;\n\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n      filter: brightness(85%);\n    }\n  }\n  .info {\n    position: absolute;\n    top: 386px;\n    padding: 0 16px;\n    .name {\n      display: flex;\n      align-items: center;\n      align-content: center;\n      margin-bottom: 16px;\n      p {\n        color: rgba(255, 255, 255, 1);\n        font-size: 22px;\n        font-weight: 400;\n        line-height: 28px;\n        text-align: left;\n        margin-right: 8px;\n      }\n    }\n    .description {\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 25px;\n      text-align: left;\n      color: rgba(255, 255, 255, 1);\n      cursor: pointer;\n    }\n\n    .buttons {\n      display: flex;\n      align-items: center;\n      margin-top: 24px;\n\n      .reaction {\n        display: flex;\n        justify-content: space-between;\n        margin-left: 64px;\n        width: 160px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserProfiles_container__-dF4l`,
	"image": `UserProfiles_image__3SeGE`,
	"info": `UserProfiles_info__4Q57o`,
	"name": `UserProfiles_name__ys+g2`,
	"description": `UserProfiles_description__FPvl7`,
	"buttons": `UserProfiles_buttons__RMeAh`,
	"reaction": `UserProfiles_reaction__Lj6dq`
};
export default ___CSS_LOADER_EXPORT___;
