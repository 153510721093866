// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterStepOne_registerStepOne__0Paj1 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f7;
  padding: 0 16px;
}

.RegisterStepOne_title__XRl08 {
  margin-bottom: 24px;
}

.RegisterStepOne_button__I5WAB {
  position: absolute;
  bottom: 24px;
  left: 16px;
  right: 16px;
}

.RegisterStepOne_fieldsContainer__7AVJU {
  display: flex;
  flex-direction: column;
  gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterStepOne/RegisterStepOne.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".registerStepOne {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: #f2f2f7;\n  padding: 0 16px;\n}\n\n.title {\n  margin-bottom: 24px;\n}\n\n.button {\n  position: absolute;\n  bottom: 24px;\n  left: 16px;\n  right: 16px;\n}\n\n.fieldsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerStepOne": `RegisterStepOne_registerStepOne__0Paj1`,
	"title": `RegisterStepOne_title__XRl08`,
	"button": `RegisterStepOne_button__I5WAB`,
	"fieldsContainer": `RegisterStepOne_fieldsContainer__7AVJU`
};
export default ___CSS_LOADER_EXPORT___;
