import React, { FC } from "react";

import styles from "./HintOnCommonFunctions.module.scss";
import { SwipperIcon } from "../../icons/SwipperIcon";
import { ReturnIcon } from "../../icons/ReturnIcon";
import classNames from "classnames";

type HintOnCommonFunctionsProps = { isClosed: boolean; onClick: () => void };
const HintOnCommonFunctions: FC<HintOnCommonFunctionsProps> = ({
  isClosed,
  onClick,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isClosed]: isClosed,
      })}
      onClick={onClick}
    >
      <div className={styles.container}>
        <p className={styles.title}>Основные функции и жесты</p>
        <div className={styles.iconsGroup}>
          <div className={styles.iconGroup}>
            <SwipperIcon />
            <div className={styles.iconGroupText}>
              Интересен человек или нет
              <span>Свайпните вправо или влево</span>
            </div>
          </div>

          <div className={styles.iconGroup}>
            <ReturnIcon />
            <div className={styles.iconGroupText}>
              Назад
              <span>Вернуться к предыдущей анкете</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HintOnCommonFunctions;
