import React, { useState } from "react";
import classNames from "classnames";
import { MainPageIcon } from "../../icons/MainPageIcon";
import { ChatsPageIcon } from "../../icons/ChatsPageIcon";
import { ProfilePageIcon } from "../../icons/ProfilePageIcon";

import styles from "./MainMenu.module.scss";

const enum TabsList {
  Main = "Главная",
  Chats = "Совпадения",
  Profile = "Профиль",
}

const MainMenu = () => {
  const [activeTab, setActiveTab] = useState(TabsList.Main);

  const tabs = [
    {
      name: TabsList.Main,
      icon: <MainPageIcon />,
      active_icon: <MainPageIcon fill="#32ADE6" />,
    },
    {
      name: TabsList.Chats,
      icon: <ChatsPageIcon />,
      active_icon: <ChatsPageIcon fill="#32ADE6" />,
    },
    {
      name: TabsList.Profile,
      icon: <ProfilePageIcon />,
      active_icon: <ProfilePageIcon fill="#32ADE6" />,
    },
  ];

  const onTabClick = (tab: TabsList) => () => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.container}>
      {tabs.map(({ name, icon, active_icon }, index) => {
        return (
          <div
            className={classNames(styles.tab, {
              [styles.tab_active]: name === activeTab,
            })}
            key={`${name}_${index}`}
            onClick={onTabClick(name)}
          >
            {activeTab === name ? active_icon : icon}
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MainMenu;
