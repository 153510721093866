import React, { useState } from "react";
import { GroupButtonLocal } from "components/GroupButtonLocal/GroupButtonLocal";
import UserProfiles from "components/UserProfiles/UserProfiles";
import MainMenu from "components/MainMenu/MainMenu";
import HintOnCommonFunctions from "../../components/HintOnCommonFunctions/HintOnCommonFunctions";

const MainSession: React.FC = () => {
  const [isHintsClosed, setIsHintsClosed] = useState(false);

  const onHintsClick = () => {
    setIsHintsClosed(true);
  };

  return (
    <div className="MainSessionContainer">
      <HintOnCommonFunctions isClosed={isHintsClosed} onClick={onHintsClick} />
      <GroupButtonLocal />
      <UserProfiles />
      <MainMenu />
    </div>
  );
};

export default MainSession;
