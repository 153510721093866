import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/authService";

const useLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initLogin = async () => {
      const InitDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

      const userString = encodeURIComponent(
        JSON.stringify(InitDataUnsafe.user)
      );
      const InitData = `auth_date=${InitDataUnsafe.auth_date}&user=${userString}&hash=${InitDataUnsafe.hash}&query_id=${InitDataUnsafe.query_id}`;

      try {
        const userData = await loginUser(InitData);

        if (userData) {
          console.log("Данные пользователя после авторизации:", userData);
          navigate("/main-session");
        } else {
          console.error(
            "Авторизация не удалась, данные пользователя отсутствуют."
          );
        }
      } catch (error) {
        console.error("Авторизация не удалась.");
      }
    };

    initLogin();
  }, [navigate]);
};

export default useLogin;
