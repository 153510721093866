import React from "react";
export const ChatsPageIcon = ({
  width = "40",
  height = "32",
  fill = "#98989E",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6333 8.54219C21.9791 6.97419 19.6648 6 17.1034 6C12.0758 6 8 9.75354 8 14.3838C8 17.0245 9.21765 19.1892 11.2896 20.7259C11.5546 20.9224 11.7987 22.0143 11.208 22.9315C10.9665 23.3066 10.7006 23.5984 10.4959 23.823C10.2001 24.1477 10.0321 24.332 10.2503 24.4248C10.478 24.5215 11.8232 24.5694 12.7942 24.0178C13.5541 23.586 14.0144 23.1553 14.3243 22.8653C14.5806 22.6255 14.7341 22.4818 14.8694 22.5132C15.3542 22.6259 15.8545 22.7027 16.3667 22.7405C16.3737 22.741 16.3807 22.7415 16.3877 22.742C16.6239 22.7589 16.8626 22.7676 17.1034 22.7676C22.1311 22.7676 26.2069 19.014 26.2069 14.3838C26.2069 12.1124 25.2261 10.052 23.6333 8.54219Z"
        fill={fill}
      />
      <path
        d="M32 16.8989C32 13.6235 29.9605 10.7869 26.9873 9.40721C26.6256 9.23939 26.2997 9.66858 26.4891 10.0229C27.1857 11.326 27.5807 12.803 27.5807 14.3838C27.5807 19.0823 24.0916 22.8639 19.5631 23.886C19.1654 23.9758 19.0773 24.521 19.4594 24.6645C20.5199 25.0631 21.6804 25.2827 22.8966 25.2827C23.6674 25.2827 24.4158 25.1945 25.1306 25.0284C25.2659 24.9969 25.4194 25.1406 25.6757 25.3804C25.9856 25.6705 26.4459 26.1012 27.2058 26.5329C28.1768 27.0845 29.522 27.0367 29.7497 26.9399C29.9679 26.8472 29.7999 26.6628 29.5041 26.3381C29.2994 26.1135 29.0335 25.8217 28.792 25.4467C28.2013 24.5295 28.4454 23.4375 28.7104 23.241C30.7823 21.7043 32 19.5397 32 16.8989Z"
        fill={fill}
      />
    </svg>
  );
};
