import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../services/registerService";
import { toast } from "react-toastify";

const useRegister = () => {
  const navigate = useNavigate();

  const initRegister = useCallback(async () => {
    const InitDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

    const userString = JSON.stringify(InitDataUnsafe.user);
    const InitData = `auth_date=${InitDataUnsafe.auth_date}&user=${userString}&hash=${InitDataUnsafe.hash}&query_id=${InitDataUnsafe.query_id}`;

    try {
      const userData = await registerUser(InitData);
      navigate("/main-session");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("Пользователь с такими данными уже существует.");
        navigate("/main-session");
      } else {
        console.error("Регистрация не удалась.", error);
      }
    }
  }, [navigate]);

  return initRegister;
};

export default useRegister;
