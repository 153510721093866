import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/Title/Title";
import InputAbout from "components/Input/InputAbout/InputAbout";
import Button from "components/Button/Button";
import styles from "./RegisterStepTwo.module.scss";
import Stepper from "components/Stepper/Stepper";
import Text from "components/Text/Text";

const RegisterStepTwo: React.FC = () => {
  const [aboutText, setAboutText] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/register-step-three");
  };

  const handleAboutChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => { 
    const value = e.target.value;
    setAboutText(value);
    setIsButtonDisabled(!value.trim());
  };

  return (
    <div className={styles.registerStepTwo}>
      <Stepper currentStep={2} />
      <div className={styles.title}>
        <Title firstTitle="Расскажите о себе" />
      </div>
      <Text />
      <div className={styles.inputAbout}>
        <InputAbout text={aboutText} onChange={handleAboutChange} />
      </div>
      <div className={styles.button}>
        <Button
          title="Продолжить"
          onClick={handleNextClick}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default RegisterStepTwo;
