import React from "react";

export const CancelIcon = () => (
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.54047 19.8702L13.6194 12.3897L21.6984 19.8702M21.6984 4.90918L13.6179 12.3897L5.54047 4.90918"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
