// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stepper_container__XcJat {
  height: 5px;
  margin: 12px 0 32px;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 1px 1px;
  display: flex;
  flex: 1 1;
  align-items: center;
  position: relative;
}

.Stepper_step__GSFXm {
  display: block;
  position: absolute;
  left: 1px;
  background-color: #98989e;
  border-radius: 12px;
  height: 3px;
  width: 32.8333333333%;
}

.Stepper_secondStep__F4OoY {
  left: 33.3333333333%;
}

.Stepper_thirdStep__Saujw {
  left: 66.6666666667%;
}`, "",{"version":3,"sources":["webpack://./src/components/Stepper/Stepper.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,SAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,qBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".container {\n  height: 5px;\n  margin: 12px 0 32px;\n  border-radius: 12px;\n  border: 1px solid transparent;\n  background-color: #ffffff;\n  padding: 1px 1px;\n  display: flex;\n  flex: 1;\n  align-items: center;\n  position: relative;\n}\n\n.step {\n  display: block;\n  position: absolute;\n  left: 1px;\n  background-color: #98989e;\n  border-radius: 12px;\n  height: 3px;\n  width: calc(1 / 3 * 100% - 0.5%);\n}\n\n.secondStep {\n  left: calc(1 / 3 * 100%);\n}\n\n.thirdStep {\n  left: calc(2 / 3 * 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Stepper_container__XcJat`,
	"step": `Stepper_step__GSFXm`,
	"secondStep": `Stepper_secondStep__F4OoY`,
	"thirdStep": `Stepper_thirdStep__Saujw`
};
export default ___CSS_LOADER_EXPORT___;
