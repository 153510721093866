import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RegisterStepOne from "pages/RegisterStepOne/RegisterStepOne";
import RegisterStepTwo from "pages/RegisterStepTwo/RegisterStepTwo";
import RegisterStepThree from "pages/RegisterStepThree/RegisterStepThree";
import Container from "components/Container/Container";
import "react-toastify/dist/ReactToastify.css";
import "./scss/App.scss";
import Logo from "components/Logo/Logo";
import MainSession from "pages/MainSession/MainSession";

function App() {
  return (
    <div className="App">
      <ToastContainer position="bottom-right" />
      <Router>
        <Container>
          <Logo />
          <Routes>
            <Route path="/" element={<RegisterStepOne />} />
            <Route path="/register-step-two" element={<RegisterStepTwo />} />
            <Route
              path="/register-step-three"
              element={<RegisterStepThree />}
            />
            <Route path="/main-session" element={<MainSession />} />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
