// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status_statusContainer__sc3xn {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  align-content: center;
}
.Status_statusContainer__sc3xn p {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
}
.Status_statusContainer__sc3xn p:first-child {
  margin-right: 8px;
}
.Status_statusContainer__sc3xn p span {
  margin-right: 4px;
  display: flex;
  align-items: center;
  align-content: center;
}
.Status_statusContainer__sc3xn .Status_blackLocation__8FdeI {
  width: 16px;
  height: 16px;
  stroke: black;
}`, "",{"version":3,"sources":["webpack://./src/components/Status/Status.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;EACA,oCAAA;AACJ;AAAI;EACE,iBAAA;AAEN;AACI;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AACN;AAEE;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".statusContainer {\n  display: flex;\n  margin-bottom: 8px;\n  align-items: center;\n  align-content: center;\n\n  p {\n    display: flex;\n    justify-content: space-between;\n    padding: 4px 12px;\n    border-radius: 30px;\n    background-color: rgba(255, 255, 255, 1);\n    &:first-child {\n      margin-right: 8px;\n    }\n\n    span {\n      margin-right: 4px;\n      display: flex;\n      align-items: center;\n      align-content: center;\n    }\n  }\n  .blackLocation {\n    width: 16px;\n    height: 16px;\n    stroke: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusContainer": `Status_statusContainer__sc3xn`,
	"blackLocation": `Status_blackLocation__8FdeI`
};
export default ___CSS_LOADER_EXPORT___;
