import React from "react";
export const ProfilePageIcon = ({
  width = "40",
  height = "32",
  fill = "#98989E",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 22.6274 26.6274 28 20 28C13.3726 28 8 22.6274 8 16C8 9.37258 13.3726 4 20 4C26.6274 4 32 9.37258 32 16ZM23.6923 13.2308C23.6923 15.27 22.0392 16.9231 20 16.9231C17.9608 16.9231 16.3077 15.27 16.3077 13.2308C16.3077 11.1916 17.9608 9.53846 20 9.53846C22.0392 9.53846 23.6923 11.1916 23.6923 13.2308ZM26.9873 22.3905C26.8249 22.0006 26.5828 21.8407 26.0987 21.5208C24.3496 20.3651 22.2534 19.6923 20.0001 19.6923C17.7467 19.6923 15.6505 20.3652 13.9014 21.5209C13.4173 21.8408 13.1752 22.0008 13.0128 22.3906C12.9001 22.6612 12.9001 23.1851 13.0128 23.4556C13.1753 23.8455 13.4173 24.0054 13.9014 24.3253C15.6505 25.481 17.7467 26.1538 20 26.1538C22.2534 26.1538 24.3496 25.481 26.0987 24.3252C26.5828 24.0053 26.8249 23.8454 26.9873 23.4555C27.1 23.185 27.1 22.661 26.9873 22.3905Z"
        fill={fill}
      />
    </svg>
  );
};
