import React, { FC } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

type ButtonType = "previous" | "cancel" | "like" | "reload";

type ButtonProps = {
  title: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
};

const Button: FC<ButtonProps> = ({ title, onClick, disabled, type }) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={classNames(styles.button, {
        [styles.disabled]: disabled,
        [styles.previous]: type === "previous", 
        [styles.cancel]: type === "cancel", 
        [styles.like]: type === "like",
        [styles.reload]: type === "reload",
      })}
    >
      {title}
    </div>
  );
};

export default Button;
