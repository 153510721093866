import React, { FC, useState } from "react";
import styles from "./UserProfiles.module.scss";
import { VerifiedIcon } from "icons/VerifiedIcon";
import Status from "components/Status/Status";
import Button from "components/Button/Button";
import { PreviousIcon } from "icons/PreviousIcon";
import { LikeIcon } from "icons/LikeIcon";
import { CancelIcon } from "icons/CancelIcon";
import useFetchProfiles from "hooks/useFetchProfiles";
import { likeForm, dislikeForm } from "services/reactionService";

const UserProfiles: FC = () => {
  const { profiles, loading, error } = useFetchProfiles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canceledProfiles, setCanceledProfiles] = useState<number[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLike = async () => {
    if (profiles[currentIndex]) {
      try {
        await likeForm(profiles[currentIndex].id);
        if (currentIndex < profiles.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
      } catch (error) {
        console.error("Ошибка при установке лайка:", error);
      }
    }
  };

  const handleCancel = async () => {
    if (profiles[currentIndex]) {
      try {
        await dislikeForm(profiles[currentIndex].id);
        if (currentIndex < profiles.length - 1) {
          setCanceledProfiles([...canceledProfiles, currentIndex]);
          setCurrentIndex(currentIndex + 1);
        }
      } catch (error) {
        console.error("Ошибка при установке дизлайка:", error);
      }
    }
  };

  const handlePrevious = () => {
    if (canceledProfiles.length > 0) {
      const lastCanceledIndex = canceledProfiles[canceledProfiles.length - 1];
      setCanceledProfiles(canceledProfiles.slice(0, -1));
      setCurrentIndex(lastCanceledIndex);
    }
  };

  return (
    <div className={styles.container}>
      {profiles[currentIndex] && (
        <div key={profiles[currentIndex].id} className={styles.profile}>
          <div className={styles.image}>
            <img
              src={profiles[currentIndex].photo_links[0]}
              alt={profiles[currentIndex].name}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.status}>
              <Status status="Был сегодня" place={"Unknown"} />
            </div>
            <div className={styles.name}>
              <p>
                {profiles[currentIndex].name}, {profiles[currentIndex].age}
              </p>
              <VerifiedIcon />
            </div>
            <div className={styles.description}>
              <p onClick={toggleText}>
                {isExpanded
                  ? profiles[currentIndex].description
                  : profiles[currentIndex].description.slice(0, 100) + "..."}
              </p>
            </div>
            <div className={styles.buttons}>
              <Button
                title={<PreviousIcon />}
                type="previous"
                onClick={handlePrevious}
              />
              <div className={styles.reaction}>
                <Button
                  title={<CancelIcon />}
                  type="cancel"
                  onClick={handleCancel}
                />
                <Button title={<LikeIcon />} type="like" onClick={handleLike} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfiles;
