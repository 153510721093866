import axios from "axios";

export const loginUser = async (InitData) => {
  try {
    const response = await axios.post(
      "https://swiperapp.ru:3000/auth/login",
      null,
      {
        params: {
          InitData: InitData,
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    console.log("Ответ от сервера:", response);

    if (response && response.data) {
      const { user, access_token } = response.data;

      if (access_token) {
        localStorage.setItem("access_token", access_token);
        console.log("Access token сохранён.");
      } else {
        console.error("Access token отсутствует в ответе от сервера.");
      }

      return response.data;
    } else {
      console.error("Ответ от сервера не содержит данных.");
      return null;
    }
  } catch (error) {
    console.error("Ошибка авторизации:", error.message);
    throw error;
  }
};
